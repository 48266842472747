import styled from '@emotion/styled'
import anchoTheme, { FlexDirection } from '@lib/theme'

export enum ParagraphColor {
  Dark = 'dark',
  Error = 'error',
  Grey = 'grey',
  Info = 'info',
  Light = 'light',
  Primary = 'primary',
  Warn = 'warn',
}

interface PProps {
  color?: ParagraphColor
  direction?: FlexDirection
  small?: boolean
}

export const P = styled.p<PProps>(
  {
    aligntContent: 'inherit',
    alignItems: 'inherit',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    justifyItems: 'center',
    lineHeight: '1.75',
    margin: 0,

    '&:not(:first-of-type)': {
      marginTop: '15px',
    },
  },
  ({
    color,
    small = false,
    direction = FlexDirection.Column,
    theme = anchoTheme,
  }) => ({
    color: color ? theme.colors[color] : 'inherit',
    flexDirection: direction,
    fontSize: small ? '1rem' : '1.125rem',
  }),
)
