import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

const Input = styled.input(
  {
    alignContent: 'center',
    borderBottomLeftRadius: '22.5px',
    borderBottomRightRadius: '5px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '22.5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    fontFamily: '"canada-type-gibson", sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    height: '45px',
    outline: 'none',
    padding: '10px 22.5px',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    borderColor: theme.colors.grey,
    color: theme.colors.dark,

    '&:focus': {
      borderColor: theme.colors.black,
    },

    '&::placeholder': {
      color: theme.colors.grey,
    },
  }),
)

export default Input
