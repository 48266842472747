import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

const Label = styled.label(
  {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    marginBottom: '5px',
    textTransform: 'uppercase',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    color: theme.colors.grey,
  }),
)

export default Label
