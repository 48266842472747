import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import anchoTheme from '@lib/theme'

export const OrganizationName = styled.div(
  {
    alignContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.125rem',
    fontWeight: 600,
    letterSpacing: '1px',
    padding: '7.5px 15px',
    textTransform: 'uppercase',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    color: theme.colors.grey,

    '&:hover': {
      color: theme.colors.primary,
    },

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.light}`,
    },
  }),
)

export const AuthFormWrapper = styled.div({
  margin: 'auto',

  [`${mediaQueries.mobile}`]: {
    width: '100%',
  },
  [`${mediaQueries.tablet}`]: {
    width: '80%',
  },
  [`${mediaQueries.desktop}`]: {
    width: '60%',
  },
  [`${mediaQueries.largeDesktop}`]: {
    width: '40%',
  },
})
