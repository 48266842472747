import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

interface FormHeaderProps {
  align?: 'center' | 'left' | 'right'
}

export const FormHeader = styled.h1<FormHeaderProps>(
  {
    display: 'block',
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '15px!important',
    paddingBottom: '15px',
    textTransform: 'uppercase',
    width: '100%',
  },
  ({ align = 'left', theme = anchoTheme }) => ({
    borderBottom: `1px solid ${theme.colors.light}`,
    textAlign: align,
  }),
)

export const FormGroup = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',

  '&:not(:first-of-type)': {
    marginTop: '15px',
  },
})

const Form = styled.form({
  margin: 0,
  padding: 0,
  width: '100%',
})

export default Form
