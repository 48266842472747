import { FC } from 'react'
import { AuthFormWrapper } from './style'
import LoginForm from './login'
import Card from '@components/core/card'
import SignupForm from './signup'

export enum AuthFormType {
  Login = 'login',
  Signup = 'signup',
}

interface AuthFormProps {
  type: AuthFormType
}

const AuthForm: FC<AuthFormProps> = ({ type }) => {
  return (
    <AuthFormWrapper>
      <Card elevation={2}>
        {type === AuthFormType.Login && <LoginForm />}
        {type === AuthFormType.Signup && <SignupForm />}
      </Card>
    </AuthFormWrapper>
  )
}

export default AuthForm
