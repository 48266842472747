import styled from '@emotion/styled'

const FormButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '30px',
  width: '100%',
})

export default FormButtonWrapper
