import Block from '@components/core/block'
import { PrimaryButton } from '@components/core/buttons'
import Form, { FormGroup, FormHeader } from '@components/core/form'
import FormButtonWrapper from '@components/core/form/button-wrapper'
import Input from '@components/core/form/input'
import Label from '@components/core/form/label'
import { P } from '@components/core/paragraph'
import { useStytchB2BClient } from '@stytch/nextjs/b2b'
import { useRouter } from 'next/router'
import { FC, FormEvent, useState } from 'react'

const SignupForm: FC = () => {
  const router = useRouter()
  const stytchClient = useStytchB2BClient()

  const [email, setEmail] = useState<string>('')
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const sendResponse = await stytchClient.magicLinks.email.discovery.send({
        email_address: email,
        discovery_redirect_url: `${window.location.origin}/auth/verify`,
      })

      if (sendResponse?.status_code === 200) {
        localStorage.removeItem('ancho:lastOrganizationId')
        setEmailSent(true)
      }
    } catch (error) {
      // Handle error
      console.error('Error sending signup email', error)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {!emailSent && (
        <>
          <FormHeader align="center">Sign up</FormHeader>
          <FormGroup>
            <Label>Your work email</Label>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@company.com"
              type="email"
              value={email}
            />
          </FormGroup>

          <FormButtonWrapper>
            <PrimaryButton type="submit">Signup</PrimaryButton>
          </FormButtonWrapper>
        </>
      )}

      {emailSent && (
        <>
          <FormHeader align="center">Check your email</FormHeader>
          <Block>
            <P>
              <div>
                We've sent a login link to <strong>{email}</strong>. Click the
                link in the email to log in.
              </div>
            </P>
            <P>You can now close this window.</P>
          </Block>
        </>
      )}
    </Form>
  )
}

export default SignupForm
