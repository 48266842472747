import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'

interface BlockProps {}

const Block = styled.div<BlockProps>(
  {
    display: 'block',
    maxWidth: '100%',
    width: '100%',

    '&:not(:first-of-type)': {
      marginTop: '30px',
    },
    '&:last-of-type': {
      marginBottom: '30px',
    },
  },
  ({}) => ({}),
)

export default Block
