import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { useStytchB2BClient } from '@stytch/nextjs/b2b'

import { PrimaryButton } from '@components/core/buttons'
import Form, { FormGroup, FormHeader } from '@components/core/form'
import FormButtonWrapper from '@components/core/form/button-wrapper'
import Input from '@components/core/form/input'
import Label from '@components/core/form/label'

import { useAncho } from '@context/ancho'
import Block from '@components/core/block'
import { P } from '@components/core/paragraph'
import Divider from '@components/core/divider'
import { H5 } from '@components/core/headings'

const LoginForm: FC = () => {
  const stytchClient = useStytchB2BClient()

  const [email, setEmail] = useState<string>('')
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [organizationId, setOrganizationId] = useState<string>('')

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/

    setEmail(email)
    setEmailIsValid(emailRegex.test(email))
  }

  const handleEmailMagicLink = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    localStorage.removeItem('ancho:lastOrganizationId')

    const response = await stytchClient.magicLinks.email.discovery.send({
      email_address: email,
      discovery_redirect_url: `${window.location.origin}/auth/verify`,
    })

    if (response.status_code === 200) {
      setEmailSent(true)
    }
  }

  useEffect(() => {
    const lastOrganizationId = localStorage.getItem('ancho:lastOrganizationId')

    if (lastOrganizationId) {
      setOrganizationId(lastOrganizationId)
    }
  }, [])

  return (
    <>
      <Block>
        {!emailSent && (
          <>
            {organizationId && organizationId.length > 0 && (
              <FormHeader>Log in with SSO</FormHeader>
            )}

            <Block>
              {organizationId && organizationId.length > 0 && (
                <>
                  <PrimaryButton
                    fullWidth
                    onClick={() => {
                      stytchClient.oauth.google.start({
                        organization_id: organizationId,
                        login_redirect_url: `${window.location.origin}/auth/verify`,
                        signup_redirect_url: `${window.location.origin}/auth/verify`,
                      })
                    }}
                  >
                    Login with Google
                  </PrimaryButton>

                  <PrimaryButton
                    fullWidth
                    onClick={() =>
                      stytchClient.oauth.microsoft.start({
                        organization_id: organizationId,
                        login_redirect_url: `${window.location.origin}/auth/verify`,
                        signup_redirect_url: `${window.location.origin}/auth/verify`,
                      })
                    }
                  >
                    Login with Microsoft
                  </PrimaryButton>

                  <Divider
                    style={{ marginBottom: '30px', marginTop: '30px' }}
                  />
                </>
              )}

              <Form onSubmit={handleEmailMagicLink}>
                <FormHeader>Log in with your email</FormHeader>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    onChange={handleEmailChange}
                    type="email"
                    value={email}
                  />
                </FormGroup>
                <FormButtonWrapper>
                  <PrimaryButton disabled={!emailIsValid} type="submit">
                    Get a login link
                  </PrimaryButton>
                </FormButtonWrapper>
              </Form>
            </Block>
          </>
        )}
        {emailSent && (
          <>
            <FormHeader align="center">Check your email</FormHeader>
            <Block>
              <P>
                <div>
                  We've sent a login link to <strong>{email}</strong>. Click the
                  link in the email to log in.
                </div>
              </P>
              <P>You can now close this window.</P>
            </Block>
          </>
        )}
      </Block>
    </>
  )
}

export default LoginForm
