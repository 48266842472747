import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'
import { darken } from 'polished'

const Divider = styled.div(
  {
    borderBottom: '1px solid',
    margin: '15px 0',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    borderColor: darken(0.05, theme.colors.light),
  }),
)

export default Divider
